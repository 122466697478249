import React from 'react'
import colors from '../../Colors.scss'

const News = ({ color = colors.color_sidebar_icon, width = '30', height = '30' }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 141.7 141.7"
           width={width}
           height={height}
           fill={color}
      >
          <path className="st1"
                d="M82.2,116.2h-22.7v11.3h22.7v-11.3ZM87.8,99.2h-34v11.3h34v-11.3ZM96.5,52.3c0,7.3-3.3,14.1-8.6,18.9v-16.2h-11.3v27.2h-11.3v-27.2h-11.3v16.2c-5.3-4.8-8.6-11.6-8.6-18.9,0-14.1,11.5-25.6,25.6-25.6s25.6,11.5,25.6,25.6M108.9,52.3c0-21-17.1-38.1-38.1-38.1s-38.1,17.1-38.1,38.1,8.4,27.4,21.1,33.9v7.4h34v-7.4c12.7-6.4,21.1-19.5,21.1-33.8"/>
      </svg>
  )
}

export default News
