import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Material UI components
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CloseIcon from '@mui/icons-material/Close'

// React Intl
// Styles
import './Header.scss'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'

// Local Components
import HeadLogo from './HeadLogo'
import AvatarComponent from '../Avatar/Avatar'
import Presence from '../Presence/Presence'

// SVG

// Redux Actions
import { addEventId, addLastUrl } from '../../redux/slices/globalValue/globalValue'
import { addSendBird } from '../../redux/slices/SendBirdSlice/SendBirdSlice'
import { connectToSB } from '../../pages/InboxPage/SendBird/action'

// Queries
import { useGetDetailUserQuery, useGetUserAccessQuery } from '../../redux/api/userApi/userApi'
import { useGetAttendeeProfileQuery } from '../../redux/api/authApi/authApi'

// Helpers
// Hooks
import { useAuthValue, useGlobalValue } from '../../hooks/redux/reduxHooks'
import { handleLogout } from '../../helper/header/login'
import HeaderActions from './headerActions/HeaderActions'
import { connectSbAndCheckMsgCount } from '../../helper/header/countUnreadMsg'
import GreaterThanIcon from '../../SVG/components/header/GreaterThanIcon'

const Header = () => {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // custom hooks
  const { eventId } = useGlobalValue()
  const { login, profileDetails, userId } = useAuthValue()

  // url
  const href = document.location.href.split('/')
  const chatHref = href[href.length - 2]

  const { data: userAccess } = useGetUserAccessQuery(eventId)
  const chat_enabled = userAccess?.event_features?.chat_enabled
  const hotline_enabled = userAccess?.event_features?.hotline_enabled

  // state
  const [open, setOpen] = useState({ header: false, anchor: null })
  const sendBird = useSelector(state => state.sendBirdSliceReducer.sendBird)
  // api
  const { refetch } = useGetDetailUserQuery('', {
    skip: !login
  })
  const { data: profileDetailsSB } = useGetAttendeeProfileQuery(userId, {
    skip: !login
  })

  useEffect(() => {
    if (userId) {
      refetch()
    }
  }, [userId])

  const connectAndCheck = useCallback(async ({ sendBird, dispatch, href, profileDetailsSB, chatHref }) => {
    try {
      dispatch(addSendBird(sendBird))
      await connectSbAndCheckMsgCount({ sendBird, dispatch, href, profileDetailsSB, chatHref })
      dispatch(addLastUrl(''))
    } catch (e) {
    }
  }, [profileDetailsSB])

  useEffect(() => {
    (async () => {
      if (!(chat_enabled || hotline_enabled)) return
      if (href.includes('events')) {
        const sendBird = await connectToSB()
        dispatch(addSendBird(sendBird))
        await connectAndCheck({ sendBird, dispatch, href, profileDetailsSB, chatHref })
      }
    })()
    dispatch(addLastUrl(''))
  }, [profileDetailsSB, userAccess])

  useEffect(() => {
    if (!(chat_enabled || hotline_enabled)) return
    if (href.includes('/events') && !eventId) {
      dispatch(addEventId({ eventId: href[4] }))
    }
    if (href.includes('hotline') || href.includes('inbox') && !sendBird) {
      (async () => {
        if (href.includes('events')) {
          const sendBird = await connectToSB()
          dispatch(addSendBird(sendBird))
          await connectAndCheck({ sendBird, dispatch, href, profileDetailsSB, chatHref })
        }
      })()
    }
  }, [href, userAccess])

  return (
        <div className="header-page-container">
            <div className="header-page-main">
                <HeadLogo/>
                <div className="desktop-head">
                    <HeaderActions/>
                </div>

                <div className="mobile-head">
                    <div className="mobile-header-side">
                        {open.header
                          ? (
                                <CloseIcon
                                    onClick={() => setOpen((prev) => ({ ...prev, header: false }))}
                                    fontSize="large"
                                />
                            )
                          : (
                                <MenuOutlinedIcon
                                    onClick={() => setOpen((prev) => ({ ...prev, header: true }))}
                                    fontSize="large"
                                />
                            )}
                    </div>

                </div>

            </div>
            <div className="mobile-head2">
                {open.header ? (
                    <div className="header-page-actions">

                        {userId ? (
                            <>
                                <div className="header-login">
                                    <div className="header-login-container">
                                        <AvatarComponent alt="dp"
                                                         url={profileDetails?.avatar?.download_url}
                                                         status_online={profileDetails?.status_online}/>
                                        <div className="header-popover">
                                            <div className="header-popover-text">
                                                <div className="header-login__status">

                                                    <p className="userName">{profileDetails?.full_name?.split(' ').join(' ')}</p>
                                                    <Presence status={profileDetails?.attendee_type}/>

                                                </div>
                                                <p className="userEmail">{profileDetails?.email}</p>
                                            </div>
                                            <div onClick={() => {
                                              setOpen((prev) => ({ ...prev, header: false }))
                                              navigate('/user/editing-profile/')
                                            }}>
                                                <GreaterThanIcon height={24} width={24}/>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <p><FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"/></p> */}
                                    {/* <p><FormattedMessage id="about-us" defaultMessage="About Us"/></p> */}
                                    {/* <p><FormattedMessage id="contact-us" defaultMessage="Contact Us"/></p> */}
                                    {/* <p><FormattedMessage id="action-link" defaultMessage="Action Link"/></p> */}
                                    {/*  <p><a href={'mailto: hotline@gallusmedia.ch'}><FormattedMessage id="support-us" defaultMessage="Support"/></a></p> */}
                                    {/* <p><a href={'https://app.colorectalsurgery.eu/Complete_Guide_ECC-App.pdf'} target={'_blank'} rel="noreferrer"><FormattedMessage id="guide-us" defaultMessage="Guide"/></a></p> */}
                                    {/* <p><a href={'https://www.sfs.com/ch/en/data-privacy/'} target={'_blank'} rel="noreferrer"><FormattedMessage id="privacy-us" defaultMessage="Privacy Policy"/></a></p> */}
                                </div>

                                <GradiantBtn
                                    onClick={() => {
                                      handleLogout({ dispatch })
                                      navigate('/')
                                      setOpen({ header: false, anchor: null })
                                    }}
                                >
                                    {t('header.logout')}
                                </GradiantBtn>
                            </>
                        ) : (
                            <div className="header-logout-container">
                                <div>
                                    {/* <p><FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"/></p> */}
                                    {/* <p><FormattedMessage id="about-us" defaultMessage="About Us"/></p> */}
                                    {/* <p><FormattedMessage id="contact-us" defaultMessage="Contact Us"/></p> */}
                                    {/* <p><FormattedMessage id="action-link" defaultMessage="Action Link"/></p> */}
                                    {/* <p><a href={'mailto: hotline@gallusmedia.ch'}><FormattedMessage id="support-us" defaultMessage="Hotline"/></a></p> */}
                                    {/* <p><a href={'https://app.colorectalsurgery.eu/Complete_Guide_ECC-App.pdf'} target={'_blank'} rel="noreferrer"><FormattedMessage id="guide-us" defaultMessage="Guide"/></a></p> */}
                                    {/* <p><a href={'https://www.sfs.com/ch/en/data-privacy/'} target={'_blank'} rel="noreferrer"><FormattedMessage id="privacy-us" defaultMessage="Privacy Policy"/></a></p> */}

                                </div>
                                <div>
                                    <GradiantBtn
                                        onClick={() => {
                                          navigate('/sign-in')
                                          setOpen((prev) => ({ header: false, anchor: null }))
                                        }}
                                    >
                                        {t('header.login')}
                                    </GradiantBtn>
                                </div>

                            </div>
                        )}
                    </div>
                ) : (
                  ''
                )}
            </div>
        </div>
  )
}
export default Header
