import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Material UI components
import CircularProgress from '@mui/material/CircularProgress'

// Styles
import './Layout.scss'
import colors from '../../Colors.scss'

// Local components
// import GetLivePoll from './GetLivePoll'
import SideBar from '../SideBar'
import Header from '../Header'
import Sponsor from '../Sponsor/Sponsor'
import PostersDetails from '../../pages/Posters/PostersDetails/PostersDetails'
import TitleEvent from './TitleEvent/TitleEvent'
import StreamContainer from './StreamContainer/StreamContainer'
import { CookieBanner } from '../cookieBanner/CookieBanner'

// Actions
import { isToDay } from '../Days/Days'

// Redux
import { useGetAgendaDaysQuery, useGetEventsQuery } from '../../redux/api/agendaApi/agendaApi'
import { addSelectedDate, removeUrlFromVideo } from '../../redux/slices/globalValue/globalValue'
import { useEventStreamQuery } from '../../redux/api/homePageApi/homePageApi'

// SVG
import ExitBtn from '../../SVG/global/ExitBtn'
import NoVideo from '../../SVG/components/layout/NoVideo'
import GetLivePoll from './GetLivePoll'
import { useGetUserAccessQuery } from '../../redux/api/userApi/userApi'
import { NewsDetails } from '../../pages/News/NewsDetails/NewsDetails'

const Layout = ({ children }) => {
  // hooks
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // env
  const updateAgenda = process.env.REACT_APP_TIME_FOR_UPDATE_AGENDA

  // url
  const urlHref = window.location.pathname.split('/')
  const nameAgenda = urlHref[urlHref.length - 2]
  const eventIdDesc = urlHref.slice(-3)[0]

  // redux
  const login = useSelector(state => state.authReducer.token)
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const slug = useSelector(state => state.globalValueReducer.slug)
  const menu = useSelector(state => state.globalValueReducer.menu)
  const selectedDate = useSelector(state => state.globalValueReducer.selectedDate)
  // state
  const [url, setUrl] = useState('/')
  const [showMenu, setShowMenu] = useState(true)

  // api
  const { data: agendaDays } = useGetAgendaDaysQuery(eventId, {
    pollingInterval: updateAgenda,
    skip: !url.includes('/events')
  })

  const { data: userAccess } = useGetUserAccessQuery(eventId, { skip: !eventId })

  useGetEventsQuery({ eventId, day_id: selectedDate },
    {
      pollingInterval: updateAgenda,
      skip: (!url.includes('/events') && !agendaDays?.length),
      refetchOnMountOrArgChange: true
    })
  const { data: stream, isLoading: loadingStream, isFetching: fetchingStream } = useEventStreamQuery(eventId, {
    skip: !(login && eventId)
  })

  useEffect(() => {
    isToDay(agendaDays).then((value) => {
      dispatch(addSelectedDate(value))
    })
  }, [])

  setInterval(() => {
    setUrl(window.location.pathname)
    clearInterval()
  }, 500)

  // useEffect(() => {
  //   if (eventIdDesc) {
  //     refetch()
  //   }
  // }, [eventIdDesc])

  function handleChange (newValue) {
    setShowMenu(newValue)
  }

  useEffect(() => {
    if (!url.includes('video')) {
      dispatch(removeUrlFromVideo())
    }
    if (url.includes('/stream')) {
      setShowMenu(false)
    }
  }, [url])

  if (
    url === '/' ||
        url.includes('password') ||
        url.includes('/sign-in') ||
        url.includes('/signup') ||
        url.includes('/user') ||
        url.includes('/admin/polls')

  ) {
    return (
            <>
                <Header/>
                {url === '/' && <CookieBanner/>}
                <div className='wight-percent-100'>{children}</div>
                 {/* <Footer/> */}
            </>
    )
  } else {
    return (
            <>
                <Header/>
                <CookieBanner/>
                <div className="layout-main">

                    {fetchingStream || loadingStream && <CircularProgress className="circularProgress"/>}
                    {!(url.includes('posters') && url.includes('details')) && !(url.includes('news') && url.includes('details')) && !url.includes('exhibition') && !fetchingStream && !loadingStream && login && (
                        <div className="stream-container ">
                            <div className="layout-event-stream">
                                {stream?.meta?.total_count !== 0 &&
                                stream?.items[0] !== undefined
                                  ? (
                                        <div
                                            className={`container ${stream?.items[0]?.status !== 'PUBLISHED' ? 'bg-color-color_white' : 'bg-color-color_black'}`}
                                            style={{
                                              margin: stream?.items[0]?.status !== 'PUBLISHED' && 0
                                            }}>
                                            <div className="layout-header">
                                                <div className="layout-header-main">
                                                    <div className="layout-header-main-left">

                                                        {/* <div className="layout-header-img"> */}
                                                        {/*    <img src={event?.image?.meta?.download_url} */}
                                                        {/*         alt={event?.image?.title}/> */}
                                                        {/*    <div className="layout-header-img-border"/> */}
                                                        {/* </div> */}
                                                        <div className="layout-header-title">
                                                            {/* <h2>{ event.title }</h2> */}
                                                            <div className="speakers-header">
                                                                <TitleEvent/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="layout-header-main-right">
                                                        {/* <div className="layout-header-time"> <Clock timeZone={event?.event_timezone_offset}/></div> */}

                                                        {/* <div className="location-container"> */}
                                                        {/*    <p className="layout-header-location"><Global color={colors.color_background} height="25" width="25"/> {event?.location && event?.location} */}
                                                        {/*        </p> */}
                                                        {/*   <p className="text-color-gray-shade">{ differenceTime < 0 ? differenceTime.toString().split('-')[1] + ' hours ahead of your time' : differenceTime + ' hours behind of your time'} </p> */}
                                                        {/* </div> */}
                                                    </div>

                                                </div>

                                            </div>
                                            <StreamContainer stream={stream} showMenu={false}/>
                                        </div>
                                    )
                                  : (
                                        <div className="no-video-container">
                                            <div className="content">
                                                <NoVideo/>
                                                <p>{t('video-not-available')}</p>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                    {(url.includes('posters') && url.includes('details')) && (
                        <PostersDetails/>
                    )}
                    {(url.includes('news') && url.includes('details')) && (
                        <NewsDetails />
                    )}
                    <div
                        className={
                            `${showMenu ? 'mobile-layout' : 'mobile-layout mobile-layout-close'}
                       ${showMenu
                                ? url.includes('/exhibition')
                                    ? 'wight-percent-100'
                                    : 'wight-percent-30'
                                : 'wight-percent-3'} ${showMenu ? 'minWight-30' : 'minWight-fitContent'}`}
                    >
                        {showMenu && (
                            <>
                                <div className="wrap-add-with-child">
                                    <div className={`layout-children ${url.includes('/exhibition') ? 'h100' : ''}`}

                                    >
                                        {userAccess && userAccess?.event_features?.live_polls_enabled && <GetLivePoll/>}
                                        {url === `/events/${eventId}/${slug}/agenda/${nameAgenda}/` || parseInt(eventIdDesc) || url.includes('/exhibition')
                                          ? ''
                                          : <ExitBtn setShowMenu={setShowMenu}
                                                       colorCircle={colors.color_white}
                                                       colorPath={colors.color_blue}/>
                                        }
                                        {children}
                                    </div>
                                    {/* SFS Custom  */}
                                    {/* { */}
                                    {/*    !url.includes('/exhibition') && <Sponsor/> */}
                                    {/* } */}
                                </div>
                            </>
                        )}

                        {login && (
                            <SideBar
                                selectedprop={menu}
                                onChange={handleChange}
                                showMenu={showMenu}
                            />
                        )}
                    </div>
                </div>
                {/* <div className="description-container"> */}
                {/*    <StreamDescription desp={eventDesp}/> */}
                {/* </div> */}
                {/* <Footer/> */}
            </>
    )
  }
}

export default Layout
