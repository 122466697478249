import './attachments.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useGetUserAccessQuery } from '../../redux/api/userApi/userApi'
import QuestionIcon from '../../SVG/global/QuestionIcon'
import colors from '../../Colors.scss'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import React, { useState } from 'react'
import DownloadIcon from '../../SVG/global/DownloadIcon'

const Attachments = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const { data: userAccess } = useGetUserAccessQuery(eventId, { skip: !eventId })

  const attachments = userAccess?.attachments

  const handleDownload = async (fileUrl) => {
    window.open(fileUrl, '_blank')
  }

  // useState
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  return (
      <div className='attachments'>
          <div className="title-main">
              <div className="title">
                  {/* <DownloadsIcon width='30' height='30' color={colors.color_sidebar_icon}/> */}
                  <img src='/download-icon.svg'/>
                  <p>
                    <span className="help_span"
                          onClick={(e) => {
                            setShowModalHelper({
                              open: !showModalHelper.open,
                              event: e.currentTarget
                            })
                          }}>
                    {/* <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/> */}
                        {t('pages.attachments.downloads')}
                        {showModalHelper && <ContextHelp helpText={{
                          id: 3,
                          what: t('pages.attachments.context-help-attachments')
                        }} open={showModalHelper.open} anchorEl={showModalHelper.event}
                                                         setShowModalHelper={setShowModalHelper}/>}
                    </span>
                  </p>
              </div>
          </div>
          <div className='attachments__main'>
              {!!attachments?.length && attachments.map((el, i) => {
                return <div className="handout-container" key={i}>
                      <div className="handout-header">{el.title}</div>
                      <div className="handout-content">
                          <div className="filename-container">
                              <input
                                  type="text"
                                  className="filename-input"
                                  value={el.meta.filename}
                                  readOnly
                              />
                          </div>
                          <button className="download-button" onClick={() => {
                            handleDownload(el.meta.download_url)
                          }}>{t('pages.attachments.download')}
                          </button>
                      </div>
                  </div>
              })}
          </div>
      </div>
  )
}

export default Attachments
