import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'

// Component imports
import './PublicChat.scss'
import '../PrivateChat/PrivatChat.scss'
import '../../../../assets/scss/Global.scss'
import { previousMessage, userIsOnline } from '../../SendBird/action'
import Emoji from '../../../../components/Emoji/Emoji'
// import Hotline from '../../../../SVG/pages/Chats/Hotline'
import ContextHelp from '../../../../components/ContextHelp/ContextHelp'
// import adminImg from '../../../../images/admin.png'
import colors from '../../../../Colors.scss'
import ButtonMsg from '../PrivateChat/MsgContainer/Buttons/ButtonMsg'
import HeaderForMsg from '../PrivateChat/HeaderForMsg/HeaderForMsg'

// API hooks
// import { useGetAttendeeProfileQuery } from '../../../redux/api/authApi/authApi'
import { useGetEventDescQuery } from '../../../../redux/api/homePageApi/homePageApi'
import Msg from '../PrivateChat/MsgContainer/Msg/Msg'
import FormChat from '../form/formChat'
import { useSendMessage } from '../../SendBird/submitHandlerInput'
import useOpenChannelHandler from '../../../../hooks/sendBird/useOpenChannel'
import { useGetUserAccessQuery } from '../../../../redux/api/userApi/userApi'
// import QuestionIcon from '../../../../SVG/global/QuestionIcon'

// custom SFS
import Hotline from '../../../../SVG/custom/Hotline'
import QuestionsIcon from '../../../../SVG/custom/QuestionsIcon'
import adminImg from '../../../../images/sfs/admin.png'

// Constants
const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_URI = process.env.REACT_APP_BASE_URI
const limit = 50

export default function PublicChat () {
  // hooks
  const {
    setUpdateMsg, updateMsg, msgToSend, setMsgToSend,
    refreshMessage, showEmoji, setRefreshMessage, setShowEmoji,
    setMsgToCheck, msgId, setMsgId, channel, setChannel,
    setIsSend, file, setFile, setScrollBottom, scrollBottom, setLoading, loading
  } = useSendMessage()
  const { t, i18n } = useTranslation()

  // url
  const url = window.location.href
  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const token = useSelector(state => state.authReducer.token)
  const sendBird = useSelector(state => state.sendBirdSliceReducer.sendBird)
  const user = useSelector(state => state.sendBirdSliceReducer.userSendBird)

  const { data: userAccess } = useGetUserAccessQuery(eventId)

  const chat_enabled = userAccess?.event_features?.chat_enabled
  const hotline_enabled = userAccess?.event_features?.hotline_enabled

  // state
  const [messagesFromDb, setMessageFromDb] = useState([])
  const [isOnline, setIsOnline] = useState([])
  const [senderId, setSenderId] = useState([])
  const [showMsgDetail, setShowMsgDetail] = useState(false)
  const [scrollBottomOneTime, setScrollBottomOneTime] = useState('')
  const [operator, setOperator] = useState(false)
  const [chosenEmoji, setChosenEmoji] = useState(null)
  const [loader, setLoader] = useState(chat_enabled || hotline_enabled)
  const [focusInput, setFocusInput] = useState(false)

  // api
  const { data: eventDesc } = useGetEventDescQuery(eventId)

  const { channelHandler } = useOpenChannelHandler({ refreshMessage, setRefreshMessage })

  useEffect(() => {
    if (chosenEmoji) {
      setMsgToSend(msgToSend + chosenEmoji.emoji)
    }
  }, [chosenEmoji])

  const connect = async ({ chatUrl }) => {
    try {
      const isChanel = await sendBird?.openChannel.getChannel(chatUrl)
      if (isChanel && sendBird) {
        sendBird.openChannel.addOpenChannelHandler(isChanel?.url, channelHandler)
        await isChanel.enter().then(value => {
          setRefreshMessage(value)
        })

        const msgData = await previousMessage(isChanel, limit)
        if (!scrollBottomOneTime) {
          setScrollBottomOneTime('bottom')
        }
        setMessageFromDb(msgData)

        setChannel(isChanel)

        const isOperator = isChanel.operators.filter((operator) => {
          return operator?.userId === user?.userId
        })
        if (isOperator[0]) setOperator(true)
      }
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    if (sendBird && eventDesc) {
      (async () => {
        if (url.includes('hotline')) {
          await connect({ chatUrl: eventDesc.sendbird_channel_url_hotline })
        } else {
          await connect({ chatUrl: eventDesc.sendbird_channel_url })
        }
      })()
    }
  }, [url, sendBird, eventDesc])

  const SubmitHandler = async (e) => {
    e.preventDefault()
    setIsSend(true)
    setFocusInput(false)
    e.target.file.value = ''
  }

  // scroll to last message
  function useChatScroll (dev, dev2, dev3, dev4, loading) {
    const ref = useRef()
    useEffect(() => {
      if (dev3 === 'bottom' && !!dev4) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottomOneTime('close')
      }

      if (dev && loading) {
        ref.current.scrollTop = ref.current.scrollHeight
        setScrollBottom(false)
        setLoading(false)
        setLoader(false)
      }
    }, [dev, dev2, dev3, dev4, loading])
    return ref
  }

  const ref = useChatScroll(scrollBottom, refreshMessage, scrollBottomOneTime, messagesFromDb, loading)

  useEffect(() => {
    if (user) {
      previousMessage(channel, limit).then(value => {
        if (!scrollBottomOneTime) {
          setScrollBottomOneTime('bottom')
        }
        setScrollBottom(true)
        setLoading(true)
        setMessageFromDb(value)
      })
    }
  }, [refreshMessage])

  useEffect(() => {
    if (sendBird && user) {
      const ids = senderId.filter(value => value !== undefined)
      if (!ids.includes(undefined)) {
        userIsOnline(sendBird, ids).then(value => {
          setIsOnline(value)
        })
      }
    }
  }, [senderId, sendBird])

  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })
  return (
        < >
            <ToastContainer/>
            {url.includes('hotline') && <div className='title-main'>
                <div className='title'>
                    <Hotline width='30' height='30' color={colors.color_sidebar_icon}/>
                    <p>
                      <span className="help_span"
                            onClick={(e) => {
                              setShowModalHelper({
                                open: !showModalHelper.open,
                                event: e.currentTarget
                              })
                            }}>
                        {t('sidebar.hotline')}
                          {showModalHelper && <ContextHelp helpText={{
                            id: 4,
                            what: t('pages.inboxpage.chats.context-help-solves-problems')
                          }} open={showModalHelper.open}
                                                           anchorEl={showModalHelper.event}
                                                           setShowModalHelper={setShowModalHelper}/>}
                      </span>
                    </p>
                    {/*  */}
                    {/*                <span */}
                    {/*            onClick={(e) => { */}
                    {/*              setShowModalHelper({ */}
                    {/*                open: !showModalHelper.open, */}
                    {/*                event: e.currentTarget */}
                    {/*              }) */}
                    {/*            }}> */}
                    {/*        <QuestionsIcon color={colors.color_sidebar_icon} width='20' height='20'/> */}
                    {/*                  {showModalHelper && <ContextHelp helpText={{ id: 4, what: <FormattedMessage id='public-helper' defaultMessage='Solves problems at the congress regardless whether they are of a purely technical or organisational nature.'/> }} open={showModalHelper.open} anchorEl={showModalHelper.event} setShowModalHelper={setShowModalHelper}/>} */}

                    {/*      </span> */}
                    {/* </p> */}
                </div>
            </div>}

            <div className={`chat-main-public ${url.includes('hotline') ? 'chat-main-public-hotline ' : ''}`}
                 ref={ref}
                 id="chat-main-scroll">
                {url.includes('hotline') && <div className='chat-main-public-hotline-helper-mobile'
                                                 onClick={(e) => {
                                                   setShowModalHelper({
                                                     open: !showModalHelper.open,
                                                     event: e.currentTarget
                                                   })
                                                 }}>
                    <QuestionsIcon height='25' width='25' color={colors.color_grey_shade}/>
                    {showModalHelper && <ContextHelp helpText={{
                      id: 4,
                      what: t('pages.inboxpage.chats.context-help-solves-problems')
                    }} open={showModalHelper.open}
                                                     anchorEl={showModalHelper.event}
                                                     setShowModalHelper={setShowModalHelper}/>}

                </div>}
                {messagesFromDb && messagesFromDb.map((msg) => {
                  const isMessageOwnedByUser = msg?.sender?.userId === user?.userId
                  const isImage = msg?.type === 'image/jpeg' || msg?.type === 'image/png' || msg?.type === 'image/svg+xml'

                  if (!senderId.includes(msg.sender?.userId) && !isMessageOwnedByUser) setSenderId(prevArray => [...prevArray, msg.sender?.userId])
                  let imageUrl = ''

                  if (msg?.messageType === 'admin') {
                    imageUrl = adminImg
                  } else if (msg?.isOperatorMessage) {
                    imageUrl = ''
                  } else {
                    if (msg?.sender?.plainProfileUrl.includes('http')) {
                      imageUrl = `${BASE_URL + BASE_URI}/user/${msg?.sender?.metaData.django_id}/avatar/?auth_token=${token}`
                    } else {
                      imageUrl = ''
                    }
                  }

                  return (
                        <div key={msg?.createdAt} className="msg-div"
                             style={{
                               alignItems: isMessageOwnedByUser ? 'flex-end' : 'flex-start'
                             }}>
                            <HeaderForMsg msg={msg}
                                          operator={operator}
                                          msgId={msgId}
                                          showMsgDetail={showMsgDetail}
                                          setShowMsgDetail={setShowMsgDetail}
                                          user={user}
                                          publicChat={true}
                                          setMsgId={setMsgId}
                                          isOnline={isOnline}
                                          imageUrl={imageUrl}
                            />

                            <div
                                style={{
                                  height: isImage && 125,
                                  padding: '14px 16px 14px'
                                }}
                                className={isMessageOwnedByUser ? 'my-msg msg' : msg?.messageType === 'admin' ? 'not-my-msg msg admin-msg' : 'not-my-msg msg'}
                            >
                                {showMsgDetail && msg?.messageId === msgId && <div
                                    style={{ borderRadius: isMessageOwnedByUser ? ' 20px 0 20px 20px' : '0 20px 20px 20px' }}
                                    className="show-msg-detail">

                                    {operator ||
                                        isMessageOwnedByUser && <>
                                            {!isImage &&
                                                <ButtonMsg callback={() => {
                                                  setMsgToSend(msg.message)
                                                  setMsgToCheck(msg.message)
                                                  setShowMsgDetail(!showMsgDetail)
                                                  setUpdateMsg(true)
                                                  setFocusInput(true)
                                                }}
                                                           icon={<EditIcon/>}
                                                           text={t('pages.inboxpage.chats.edit')}/>}
                                            <ButtonMsg msg={msg} channel={channel}
                                                       icon={<DeleteIcon/>}
                                                       callback={() => {
                                                         if (operator) setShowMsgDetail(!showMsgDetail)
                                                       }}
                                                       text={t('pages.inboxpage.chats.delete')}/>
                                        </>

                                    }
                                    {!!msg?.type && (
                                        <Button className="msg-detail-div"
                                                style={{ justifyContent: 'space-between', color: 'black' }}

                                                onClick={(e) => {
                                                  const element = document.createElement('a')
                                                  element.href = msg?.url
                                                  document.body.appendChild(element)
                                                  element.click()
                                                }}
                                        >
                                            <p>{t('pages.inboxpage.chats.download')}</p>
                                            <ArrowCircleDownIcon/>
                                        </Button>
                                    )}

                                </div>}
                                <Msg msg={msg} user={user}/>
                            </div>
                        </div>
                  )
                })}

            </div>
            {showEmoji && <Emoji setChosenEmoji={setChosenEmoji} updateMsg={updateMsg}/>}
            {loader ? <CircularProgress className="circularProgress"/>
              : <FormChat
                    file={file}
                    updateMsg={updateMsg}
                    msgToSend={msgToSend}
                    setFile={setFile}
                    showEmoji={showEmoji}
                    setShowEmoji={setShowEmoji}
                    setMsgToSend={setMsgToSend}
                    SubmitHandler={SubmitHandler}
                    setUpdateMsg={setUpdateMsg}
                    focusInput={focusInput}
                />
            }
        </>
  )
}
