import './styles.scss'
import React, { useState } from 'react'
import DOMPurify from 'dompurify'
// import renderHTML from 'react-render-html'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

export const CookieBanner = () => {
  const userAcceptedCookies = !!localStorage.getItem('acceptedCookies')
  const [language, setLanguage] = useState('en')
  const [isShow, setIsShow] = useState(!userAcceptedCookies)

  const renderDesc = (() => {
    let clean
    switch (language) {
      case 'de':
        clean = 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer Datenschutzerklärung und Cookieerklärung.'
        break
      case 'en':
        clean = 'In order to design our website optimally for you and continuously improve it, we use cookies. By continuing to use the website, you consent to the use of cookies. You can find more information about cookies in our cookie policy and our data privacy.'
        break
      case 'fr':
        clean = 'Afin de concevoir notre site web de manière optimale pour vous et de l\'améliorer en continu, nous utilisons des cookies. En continuant à utiliser le site, vous consentez à l\'utilisation de cookies. Vous pouvez trouver plus d\'informations sur les cookies dans notre politique de confidentialité.'
        break
      case 'it':
        clean = 'Per poter progettare il nostro sito web in modo ottimale per voi e migliorarlo continuamente, utilizziamo i cookie. Continuando a utilizzare il sito web, acconsentite all\'uso dei cookie. Ulteriori informazioni sui cookie sono disponibili nella nostra informativa sulla privacy.'
        break
      default:
        return ''
      //       case 'de':
      //   clean = 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer <a href="https://www.sfs.com/ch/de/datenschutz/" target="_blank">Datenschutzerklärung</a> und <a href="https://www.sfs.com/ch/de/cookie-erkl%C3%A4rung/" target="_blank">Cookieerklärung</a>.'
      //   break
      // case 'en':
      //   clean = 'In order to design our website optimally for you and continuously improve it, we use cookies. By continuing to use the website, you consent to the use of cookies. You can find more information about cookies in our <a href="https://www.sfs.com/ch/en/cookie-declaration/" target="_blank">cookie policy</a> and our <a href="https://www.sfs.com/ch/en/data-privacy/" target="_blank">data privacy</a>.'
      //   break
      // case 'fr':
      //   clean = 'Afin de concevoir notre site web de manière optimale pour vous et de l\'améliorer en continu, nous utilisons des cookies. En continuant à utiliser le site, vous consentez à l\'utilisation de cookies. Vous pouvez trouver plus d\'informations sur les cookies dans notre politique de confidentialité.'
      //   break
      // case 'it':
      //   clean = 'Per poter progettare il nostro sito web in modo ottimale per voi e migliorarlo continuamente, utilizziamo i cookie. Continuando a utilizzare il sito web, acconsentite all\'uso dei cookie. Ulteriori informazioni sui cookie sono disponibili nella nostra informativa sulla privacy.'
      //   break
      // default:
      //   return ''
    }
    return DOMPurify.sanitize(clean, { USE_PROFILES: { html: true } })
  })()

  const renderButtonText = (() => {
    switch (language) {
      case 'de':
        return 'Ich stimme zu!'
      case 'en':
        return 'I agree!'
      case 'fr':
        return 'Je suis d\'accord !'
      case 'it':
        return 'Sono d\'accordo!'
      default:
        return ''
    }
  })()

  if (!isShow) return <></>

  return (
        <div className='banner'>
            {/* <p> dangerouslySetInnerHTML={{ __html: renderDesc }} </p> */}
            <p> { renderDesc } </p>
            <div onClick={() => {
              localStorage.setItem('acceptedCookies', 'true')
              setIsShow(false)
            // }}> dangerouslySetInnerHTML={{ __html: renderButtonText }} </div>
            }}> { renderButtonText } </div>
            {/* <FormControl size='small' id='form-control'> */}
            {/*    <Select */}
            {/*        value={language} */}
            {/*        className='banner__select' */}
            {/*        sx={{ color: '#fff', border: '1px solid #fff', paddingLeft: '15px' }} */}
            {/*        onChange={(e) => { */}
            {/*          setLanguage(e.target.value) */}
            {/*        }} */}
            {/*    > */}
            {/*        <MenuItem value="de">de</MenuItem> */}
            {/*        <MenuItem value="en">en</MenuItem> */}
            {/*        /!* <MenuItem value="fr">fr</MenuItem> *!/ */}
            {/*        /!* <MenuItem value="it">it</MenuItem> *!/ */}
            {/*    </Select> */}
            {/* </FormControl> */}
        </div>
  )
}
