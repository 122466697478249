import parse from 'html-react-parser'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import Attachments from '../../../components/Attachments/Attachments'
import { useGetNewsDetailQuery } from '../../../redux/api/newsApi/newsApi'
import './NewsDetails.scss'

export const NewsDetails = () => {
  const { t, i18n } = useTranslation()
  const newsId = window.location.href.split('/').slice(-3)[0].toString()
  const eventId = useSelector(state => state.globalValueReducer.eventId)

  const { data: newsDetail } = useGetNewsDetailQuery({ eventId, newsId })

  return (
    <div className="news-details-wrapper">
      <div className="news-header">
        <h4 style={{ fontSize: '32px' }}>{newsDetail?.title}</h4>
        <p>{newsDetail?.description}</p>
        {/* <div className="news-divider" /> */}
        {/* /!* <p> *!/ */}
        {/* /!*  <strong>{t('pages.posters.authors')} </strong> {newsDetail?.authors} *!/ */}
        {/* /!* </p> *!/ */}
        {/* <p className="poster-detail-date"> */}
        {/*  {' '} */}
        {/*  {new Date(newsDetail?.updated_at).toLocaleDateString('en-US', { */}
        {/*    day: 'numeric', */}
        {/*    month: 'long', */}
        {/*    year: 'numeric' */}
        {/*  })} */}
        {/* </p> */}
      </div>
      <div className="news-layout">
        {(newsDetail?.video || newsDetail?.image) && (
          <div>
            {!newsDetail?.video && (
              <img
                src={newsDetail?.image?.download_url}
                alt={newsDetail?.image?.title}
                style={{ width: '100%', height: '100%' }}
              />
            )}
            {newsDetail?.video ? (
              newsDetail?.video.embed_code ? (
                <>
                  {parse(
                    `<iframe className="responsive-iframe" src=${newsDetail?.video?.embed_code}  allowfullscreen frameborder="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="true"></iframe>`
                  )}
                </>
              ) : (
                <ReactPlayer
                  url={newsDetail?.video?.hls_url}
                  controls={true}
                  width="100%"
                  height="50%"
                />
              )
            ) : null}
          </div>
        )}
        <div className="video-desc-with-download">
          <div className="video-desc">
            {newsDetail?.body &&
              newsDetail?.body.map((desc, index) => {
                return <div key={index}>{parse(desc?.value)}</div>
              })}
          </div>
          {newsDetail?.attachments.length !== 0 && (
            <Attachments event={newsDetail} />
          )}
        </div>
      </div>
    </div>
  )
}
