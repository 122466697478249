import { Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetUserAccessQuery } from '../../redux/api/userApi/userApi'
import {
  addMenu,
  changeShowLiveNow
} from '../../redux/slices/globalValue/globalValue'

// src
import colors from '../../Colors.scss'
// import AgendaIcon from '../../SVG/global/AgendaIcon'
// import ArrowPlayer from '../../SVG/global/ArrowPlayer'
// import ChatIcon from '../../SVG/global/ChatIcon'
import DownloadIcon from '../../SVG/global/DownloadIcon'
import ExhibitionIcon from '../../SVG/global/ExhibitionIcon'
// import ParticipantsIcon from '../../SVG/global/ParticipantsIcon'
// import PollIcon from '../../SVG/global/PollIcon'
import PosterIcon from '../../SVG/global/PosterIcon'
// import QuestionIcon from '../../SVG/global/QuestionIcon'
// import Hotline from '../../SVG/pages/Chats/Hotline'
// import News from '../../SVG/pages/News/News'
import './SideBar.scss'

// custom SFS
import ArrowPlayer from '../../SVG/custom/ArrowPlayer'
import ParticipantsIcon from '../../SVG/custom/ParticipantsIcon'
import Hotline from '../../SVG/custom/Hotline'
import QuestionsIcon from '../../SVG/custom/QuestionsIcon'
import PollIcon from '../../SVG/custom/PollIcon'
import ChatIcon from '../../SVG/custom/ChatIcon'
import AgendaIcon from '../../SVG/custom/AgendaIcon'
import NewsIcon from '../../SVG/custom/NewsIcon'

const SideBar = props => {
  // hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // url
  const url = location.pathname.split('/')
  const showMenu = location.pathname.includes('/stream') ? true : props.showMenu
  const action = url.includes('chat') ? 'inbox' : url[4]
  const id = url[url.length - 1]

  // redux
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const slug = useSelector(state => state.globalValueReducer.slug)
  const unreadMsgCount = useSelector(
    state => state.sendBirdSliceReducer.unreadMsgCount
  )
  // state
  const [selected, setSelected] = useState(props.selectedprop)

  const onChange = props.onChange
  const { data: userAccess, refetch } = useGetUserAccessQuery(eventId, {
    skip: !eventId
  })

  // console.log('slug', slug)

  const {
    stream_player_enabled,
    agenda_enabled,
    participants_enabled,
    chat_enabled,
    polls_enabled,
    questions_enabled,
    posters_enabled,
    exhibition_enabled,
    hotline_enabled,
    media_library_enabled,
    live_now_enabled,
    news_enabled
  } = userAccess?.event_features || {}

  const { t, i18n } = useTranslation()

  useEffect(() => {
    refetch()
  }, [eventId])

  useEffect(() => {
    if (
      userAccess?.event_features?.live_now_enabled !== null &&
      userAccess?.event_features?.live_now_enabled !== undefined
    ) {
      dispatch(changeShowLiveNow(userAccess?.event_features?.live_now_enabled))
    }
  }, [userAccess])

  useEffect(() => {
    const permissions = userAccess?.event_features

    if (permissions) {
      const availablePages = {
        stream_player_enabled: `/events/${eventId}/${slug}/stream/`,
        agenda_enabled: `/events/${eventId}/${slug}/agenda`,
        participants_enabled: `/events/${eventId}/${slug}/participants/`,
        chat_enabled: `/events/${eventId}/${slug}/inbox/`,
        polls_enabled: `/events/${eventId}/${slug}/polls/`,
        questions_enabled: `/events/${eventId}/${slug}/questions/`,
        hotline_enabled: `/events/${eventId}/${slug}/hotline/`,
        posters_enabled: `/events/${eventId}/${slug}/posters/`,
        exhibition_enabled: `/events/${eventId}/${slug}/exhibition/`,
        media_library_enabled: `/events/${eventId}/${slug}/downloads/`,
        news_enabled: `/events/${eventId}/${slug}/news/`
      }

      const firstAvailablePage = Object.keys(permissions).find(key => {
        return permissions[key] && availablePages[key]
      })

      const checkAnyPages = () => {
        const availablePagesList = Object.keys(permissions)
          .map(key => {
            return permissions[key] && availablePages[key] ? key : null
          })
          .filter(Boolean)
          .map(el => availablePages[el])

        const currentPage = availablePagesList.find(el =>
          window.location.pathname.includes(el)
        )

        return !currentPage
      }

      if (
        firstAvailablePage &&
        !window.location.pathname.includes(
          availablePages[firstAvailablePage]
        ) &&
        checkAnyPages()
      ) {
        navigate(availablePages[firstAvailablePage])
        onChange(true)
      }
    }
  }, [userAccess?.event_features])

  const handleChange = to => {
    props.onChange(true)
    navigate(to)
  }

  useEffect(() => {
    if (selected !== action) {
      setSelected(action)
    }
    if (selected === 'stream' && action === 'stream') {
      setSelected(action)
    }
  }, [selected, action])

  return (
    <div
      className="sidebar-container"
      style={{
        minWidth: showMenu ? '78px' : '100%',
        maxWidth: showMenu ? (isMobile ? '' : '78px') : 'auto'
      }}
    >
            {/* News */}
      {news_enabled && (
        // <Tooltip title={t('sidebar.news')}>
        //   SFS Custom
        <Tooltip title='Breakout-Sessions'>
          <div
            className={`sidebar-icon ${
              selected === 'news' &&
              action === 'news' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/news/`)
              dispatch(addMenu('news'))
              setSelected('news')
            }}
          >
            <NewsIcon
              color={
                selected === 'news' && action === 'news' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'news' && (
              // <p className="sidebar-icon-text">{t('sidebar.news')}</p>
              //   SFS Custom
              <p className="sidebar-icon-text">Breakout-Sessions</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* / STREAM/ */}
      {stream_player_enabled && (
        <Tooltip title={t('sidebar.stream')}>
          <div
            className={`sidebar-icon ${
              selected === 'stream' &&
              action === 'stream' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              if (!url.includes('stream')) {
                handleChange(`/events/${eventId}/${slug}/stream/`)
                dispatch(addMenu('stream'))
                setSelected('stream')
              }
            }}
          >
            <ArrowPlayer
              opacity="1"
              color={
                selected === 'stream' && action === 'stream' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
              width={30}
              height={30}
              colorArrow={colors.color_white}
            />
            {selected === 'stream' && (
              <p className="sidebar-icon-text">{t('sidebar.stream')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/*  AGENDA */}
      {agenda_enabled && (
        <Tooltip title={t('sidebar.agenda')}>
          <div
            className={`sidebar-icon ${
              selected === 'agenda' &&
              action === 'agenda' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/agenda/`)
              dispatch(addMenu('agenda'))
              setSelected('agenda')
            }}
          >
            <AgendaIcon
              opacity="1"
              color={
                selected === 'agenda' && action === 'agenda' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
              width={24}
              height={24}
            />
            {selected === 'agenda' && (
              <p className="sidebar-icon-text">{t('sidebar.agenda')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* Media library */}
      {media_library_enabled && (
        <Tooltip title={t('sidebar.downloads')}>
          <div
            className={`sidebar-icon ${
              selected === 'attachments' &&
              action === 'attachments' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/attachments/`)
              dispatch(addMenu('attachments'))
              setSelected('attachments')
            }}
          >
            <DownloadIcon
              color={
                selected === 'attachments' &&
                action === 'attachments' &&
                showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'attachments' && (
              <p className="sidebar-icon-text">{t('sidebar.downloads')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* PARTICIPANTS */}
      {participants_enabled && (
        <Tooltip title={t('sidebar.participants')}>
          <div
            className={`sidebar-icon ${
              selected === 'participants' &&
              action === 'participants' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`events/${eventId}/${slug}/participants/`)
              dispatch(addMenu('participants'))
              setSelected('participants')
            }}
          >
            <ParticipantsIcon
              opacity="1"
              color={
                selected === 'participants' &&
                action === 'participants' &&
                showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
              width={24}
              height={24}
            />
            {selected === 'participants' && (
              <p className="sidebar-icon-text">{t('sidebar.participants')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* CHAT */}
      {chat_enabled && (
        <Tooltip title={t('sidebar.chat')}>
          <div
            className={`sidebar-icon ${
              selected === 'inbox' &&
              action === 'inbox' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/inbox/`)
              dispatch(addMenu('inbox'))

              setSelected('inbox')
            }}
          >
            <span className="unread__div">{unreadMsgCount || ''}</span>
            <ChatIcon
              color={
                selected === 'inbox' && action === 'inbox' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'inbox' && (
              <p className="sidebar-icon-text">{t('sidebar.chat')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* POLL */}
      {polls_enabled && (
        <Tooltip title={t('sidebar.polls')}>
          <div
            className={`sidebar-icon ${
              selected === 'polls' &&
              action === 'polls' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/polls/`)
              dispatch(addMenu('polls'))
              setSelected('polls')
            }}
          >
            <PollIcon
              color={
                selected === 'polls' && action === 'polls' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'polls' && (
              <p className="sidebar-icon-text">{t('sidebar.polls')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* QUESTIONS */}
      {questions_enabled && (
        <Tooltip title={t('sidebar.questions')}>
          <div
            className={`sidebar-icon ${
              selected === 'questions' &&
              action === 'questions' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/questions/`)
              dispatch(addMenu('questions'))
              setSelected('questions')
            }}
          >
            <QuestionsIcon
              color={
                selected === 'questions' && action === 'questions' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
              // styleVar={ cursor: 'pointer'}
            />
            {selected === 'questions' && (
              <p className="sidebar-icon-text">{t('sidebar.questions')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* POSTERS */}
      {posters_enabled && (
        <Tooltip title={t('sidebar.posters')}>
          <div
            className={`sidebar-icon ${
              selected === 'posters' &&
              action === 'posters' &&
              showMenu &&
              'border-side-bar'
            } `}
            onClick={() => {
              handleChange(`events/${eventId}/${slug}/posters/`)
              dispatch(addMenu('posters'))
              setSelected('posters')
            }}
          >
            <PosterIcon
              color={
                selected === 'posters' && action === 'posters' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'posters' && (
              <p className="sidebar-icon-text">{t('sidebar.posters')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* EXHIBITION   */}
      {exhibition_enabled && (
        <Tooltip title={t('sidebar.sponsors-exhibitors')}>
          <div
            className={`sidebar-icon ${
              selected === 'exhibition' &&
              action === 'exhibition' &&
              showMenu &&
              'border-side-bar'
            } `}
            onClick={() => {
              handleChange(`events/${eventId}/${slug}/exhibition/`)
              dispatch(addMenu('exhibition'))
              setSelected('exhibition')
            }}
          >
            <ExhibitionIcon
              color={
                selected === 'exhibition' && action === 'exhibition' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'exhibition' && (
              <p className="sidebar-icon-text">{t('sidebar.sponsors')}</p>
            )}
          </div>
        </Tooltip>
      )}

      {/* HOTLINE */}
      {hotline_enabled && (
        <Tooltip title={t('sidebar.hotline')}>
          <div
            className={`sidebar-icon ${
              selected === 'hotline' &&
              action === 'hotline' &&
              showMenu &&
              'border-side-bar'
            }`}
            onClick={() => {
              handleChange(`/events/${eventId}/${slug}/hotline/`)
              dispatch(addMenu('hotline'))
              setSelected('inbox')
            }}
          >
            <Hotline
              color={
                selected === 'hotline' && action === 'hotline' && showMenu
                  ? colors.color_selected_icon
                  : colors.color_sidebar_icon
              }
            />
            {selected === 'hotline' && (
              <p className="sidebar-icon-text">{t('sidebar.hotline')}</p>
            )}
          </div>
        </Tooltip>
      )}

    </div>
  )
}

export default SideBar
